import axios from "axios";
import { Message } from "element-ui";
import myConfig from "@/config";
import qs from "qs";

const service = axios.create({
  baseURL: myConfig.baseURL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});
service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const checkResponse = (res, reject, error) => {

      if (error) {
        Message.error(res.msg);
        reject(new Error(res.msg || "Error"));
        return false;
      }
  return true;
};

/**
 *
 * @param url   后端访问接口
 * @param params 请求携带的参数
 * @param option 配置项 error: 是否提示错误信息, mock: 本地访问数据位置, timeout: 设置请求时间
 * @returns {Promise<unknown>}
 */
export const post = (
  url,
  params = {},
  option = { error: true, timeout: 60 * 1000, isTransform: false, urlData: {} }
) => {
  const error = option.error === undefined ? true : option.error;
  const timeout = option.timeout === undefined ? 60 * 1000 : option.timeout;
  const config = { timeout };
  const data = params;
  url =
    JSON.stringify(option.urlData) != "{}"
      ? url + "?" + qs.stringify(option.urlData, { arrayFormat: "brackets" })
      : url;
  return new Promise((resolve, reject) => {
    service
      .post(url, data, config)
      .then((response) => {
        const res = response.data;
        if (res.code == 200) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        if (err.message.includes("timeout")) {
          Message.error("请求超时，请重新操作");
          reject(err);
          return;
        }
        if (error) {
          Message.error(err.message);
        }
        reject(err);
      });
  });
};

export const get = (url, params = {}) => {
  const data = qs.stringify(params, { arrayFormat: "brackets" });
  url = data ? url + "?" + data : url;
  return new Promise((resolve, reject) => {
    service
      .get(url)
      .then((response) => {
        const res = response.data;
        if (checkResponse(res, reject) && res.code == 200) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const myDelete = (url, params = {}) => {
  const data = qs.stringify(params, { arrayFormat: "brackets" });
  url = data ? url + "?" + data : url;
  return new Promise((resolve, reject) => {
    service
      .delete(url)
      .then((response) => {
        const res = response.data;
        if (checkResponse(res, reject) && res.code == 200) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const myPut = (
  url,
  params = {},
  option = { error: true, timeout: 60 * 1000, isTransform: false }
) => {
  const error = option.error === undefined ? true : option.error;
  const timeout = option.timeout === undefined ? 60 * 1000 : option.timeout;
  const config = { timeout };
  const data = params;
  return new Promise((resolve, reject) => {
    service
      .put(url, data, config)
      .then((response) => {
        const res = response.data;
        if (checkResponse(res, reject, error) && res.code == 200) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        if (err.message.includes("timeout")) {
          Message.error("请求超时，请重新操作");
          reject(err);
          return;
        }
        if (error) {
          Message.error(err.message);
        }
        reject(err);
      });
  });
};

export const download = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .create({
        baseURL: process.env.VUE_APP_PROXY,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .post(url, qs.stringify(params, { arrayFormat: "brackets" }), {
        responseType: "blob",
      })
      .then((response) => {
        const res = response.data;

        if (res.type === "application/json") {
          const reader = new FileReader();
          reader.readAsText(res);
          reader.onload = (e) => {
            checkResponse(JSON.parse(e.target.result), reject);
          };
        } else {
          const link = document.createElement("a");
          const href = window.URL.createObjectURL(res);
          link.href = href;
          let name =
            response.headers["content-disposition"].split("filename")[1];
          name = decodeURI(escape(name));
          link.download = name.replace(/"/g, "");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(href);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
