import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/index.scss";
import { post, get, myDelete, myPut, download } from "@/request";
import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueAnimateOnScroll from 'vue-animate-onscroll'
Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
};

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
};
Vue.config.productionTip = false
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$delete = myDelete;
Vue.prototype.$put = myPut;
Vue.prototype.$download = download;
Vue.use(ElementUI);
Vue.use(QuillEditor);
Vue.use(VueAnimateOnScroll);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
