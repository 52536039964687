/*
 * @Description: 无关用户权限的路由
 */
export default [
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
    },
]
