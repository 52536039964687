import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import asyncRoutes from "./asyncRoutes";
import constantRoutes from "./constantRoutes";

Vue.use(VueRouter);
NProgress.configure({
  showSpinner: false,
});

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [...constantRoutes, ...asyncRoutes],
  scrollBehavior(x, y, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  document.title = "安软科技有限公司";
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
