/*
 * @Description: 配置访问地址
 */

const isDevelopment = process.env.NODE_ENV === 'development';
const httpName = window.location.protocol.includes('https') ? 'https' : 'http';
const wsName = window.location.protocol.includes('https') ? 'wss' : 'ws';
export default {
	baseURL: isDevelopment ? '' : `${httpName}://${window.location.host}/prod-api`,
	imgURL: isDevelopment ? process.env.VUE_APP_IMG_URL : `${httpName}://${window.location.host}/upload/`,
	websocketURL: isDevelopment ? process.env.VUE_APP_WEBSOCKET_URL : `${wsName}://${window.location.hostname}/ws/msg.ws`,
};
